import { Injectable } from '@angular/core';
import { MetaOptions } from '@x/common/meta';
import { IMetaDataContent } from '@x/content/client';
import { IShopMetaContent } from '@x/ecommerce/shop-client';
import { ShopMetaContentObject } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

type ContentMeta = IMetaDataContent | undefined | null;
type ShopEntityMeta = IShopMetaContent | undefined | null;

interface metaPriority$ {
  contentMeta$?: Observable<ContentMeta>;
  entityMeta$?: Observable<ShopEntityMeta>;
  defaultMeta?: MetaOptions;
}

interface metaPriority {
  contentMeta?: ContentMeta;
  entityMeta?: ShopEntityMeta;
  defaultMeta?: MetaOptions;
}

@Injectable()
export class ShopMetaService {
  private getEntityMeta(meta: ShopMetaContentObject): MetaOptions {
    const { description, keywords, title } = meta;

    return {
      metaTitle: title,
      metaKeywords: keywords,
      metaDescription: description,
    };
  }

  private getContentMeta(meta: IMetaDataContent): MetaOptions {
    return { ...meta };
  }

  /*
   * Merge Meta by Priority
   * 1) Content meta (cms)
   * 2) Entity meta (ems)
   * 3) Default
   * */

  getMetaByPriority(priority: metaPriority): MetaOptions {
    const { contentMeta, entityMeta, defaultMeta } = priority;

    return {
      metaTitle: contentMeta?.metaTitle ?? entityMeta?.title ?? defaultMeta?.metaTitle,
      metaDescription:
        contentMeta?.metaTitle ?? entityMeta?.description ?? defaultMeta?.metaDescription,
      metaKeywords: contentMeta?.metaKeywords ?? entityMeta?.keywords ?? defaultMeta?.metaKeywords,
      metaImage: contentMeta?.metaImage ?? defaultMeta?.metaImage,
    };
  }
}
